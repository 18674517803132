import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import nft from '../assets/CH_01_00000.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class About extends Component {

    render() {
        return (

            <div class="boxWrap2Story">

                <div class="aboutA">

                    <div class="storyCon">
                        <div class="conT3">Understanding $MKK</div>
                        <p>$MKK serves as the principal currency within the Crazy Monkey King ecosystem. This digital currency is
                            designed to facilitate transactions, interactions, and engagement, becoming an essential tool for players to
                            navigate the game's multifaceted world.</p>
                    </div>

                    <img src={nft} />


                </div>

            </div>
        )
    }
}

export default About;

