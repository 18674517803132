import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import nft from '../assets/1.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Benefits extends Component {

    render() {
        return (

            <div class="boxWrap2StoryB">
                <div class="conT2">Token Distribution</div>

                <div class="about2">
                    <img src={nft} />
                    <table>
                        <tr>
                            <th>Round</th>
                            <th>Supply</th>
                         </tr>
                        <tr>
                            <td>Ecological Fund</td>
                            <td>This fund is dedicated to ensuring the continual liquidity of $MKK tokens for in-game utilization and earnings, while also
                                supporting diverse game marketing campaigns to sustain our game economy.</td>
                        </tr>
                        <tr>
                            <td>Token Sale</td>
                            <td>These tokens are earmarked for forthcoming public sales, augmenting MonkeysKing's funding sources and facilitating the
                                team's ongoing growth and game development.</td>
                        </tr>
                        <tr>
                            <td>Airdrop</td>
                            <td>Rewards are extended to early participants who hold significant importance to us. This includes NFT holders, early players,
                                cooperative communities, and exceptional community managers.
                            </td>
                        </tr>

                        <tr>
                            <td>Team + Advisors</td>
                            <td>Reserved for MonkeysKing team members and key advisors who closely collaborate to optimize MonkeysKing as the
                                epitome of gaming excellence.</td>
                        </tr>

                        <tr>
                            <td>Early Investors</td>
                            <td>Allocated to early investors who played an instrumental role during MonkeysKing's challenging phases, substantially
                                assisting the team.
                            </td>
                        </tr>

                        <tr>
                            <td>Community Treasury</td>
                            <td>Community Treasury Remaining tokens will be preserved in the community treasury to address unforeseen emergency situations.</td>
                        </tr>
                    </table>



                </div>

            </div>
        )
    }
}

export default Benefits;

