import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import docs from '../assets/doc.png';
import twitter from '../assets/twitter.png';
import base from '../assets/base.png';
import lt from '../assets/lt.png';
import opensea from '../assets/opensea.png';
import discord from '../assets/discord.png';
import logo from '../assets/logo.jpg';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const website = () => {
	window.open("https://monkeysking.com/");
}


const os = () => {
	window.open("https://opensea.io/collection/crazy-monkey-king");
}

const WP = () => {
	window.open("https://monkeysking.com/whitepaper.pdf");
}

const tweet = () => {
	window.open("https://twitter.com/MonkeyKingBase");
}

const discrd = () => {
	window.open("https://discord.gg/zHWgYdyQZF");
}

const token = () => {
	window.open("https://crazy.monkeysking.com/tokenomics/token-supply");
}

const Doc = () => {
	window.open("https://crazy.monkeysking.com/introduction/story");
}

const CLink = () => {
	window.open("https://crazy.monkeysking.com/gameplay/characters");
}

const linkTree = () => {
	window.open("https://linktr.ee/crazymonkeysking");
}

const baseLink = () => {
	window.open("https://base.org/");
}

const docLink = () => {
	window.open("https://crazy.monkeysking.com/");
}

class Footer extends Component {

    render() {
        return (

            <div class="boxWrap2Footer">

                <div class="footer">

                    <img onClick={website} class="logoF" src={logo} />
                    <div class="icons">

                        <img onClick={linkTree} src={lt} />
                        <img onClick={os} src={opensea} />
                        <img onClick={tweet} src={twitter} />
                        <img onClick={discrd} src={discord} />
                        <img onClick={baseLink} src={base} />
                        <img onClick={docLink} src={docs} />

                    </div>

                    <div class="copyright">Copyright © 2023 CMK. All Rights Reserved</div>
                </div>
            </div>
        )
    }
}

export default Footer;

